<template>
    <div>
        Encuestas
        <br>

        {{ token }}
    </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import Cookies from 'js-cookie'
const token= ref(""); 

onMounted(() => {
    token.value = Cookies.get('encuestaToken')
    console.log('Hola', token.value)
})
</script>

